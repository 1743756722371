* {
    /* scrollbar-width: none; */
    scrollbar-width: thin;
    /* overflow-x: hidden; */
  
    font-family: "Nunito";
  }
  body {
    margin: 0;
    font-family: "Nunito";
  }
  
  code {
    font-family: "Nunito";
  }
  
  @font-face {
    font-family: "Nunito";
    src: url("./assets/font/Inconsolata.ttf") format("ttf");
  }
  

  